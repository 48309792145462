import {Auth} from 'aws-amplify';

const apiRootDomain = process.env.REACT_APP_API_PATH;

// TODO: What happens if the session doesnt exist
//  does a exception get throw or just returned?
export const getUserJwt = async () => {
    try {
        const session = await Auth.currentSession();
        return session.accessToken.jwtToken;
    } catch (error) {
        throw error;

    }
}

export const getMetrics = async () => {
    try {
        const responseJSON = await fetch(`${apiRootDomain}/metrics`, {
            headers: {
                'X-Authorization': await getUserJwt(),
            },
        });
        return await responseJSON.json();
    } catch (error) {
        throw error;
    }
}

export const fetchManufacturerNames = async () => {
    try {
        const response = await fetch(`${apiRootDomain}/manufacturers`, {
            headers: {
                "X-Authorization": await getUserJwt(),
            }
        })
        let {namesToNormalize, currentManufacturerNames, currentProcessingNames} = await response.json();

        currentProcessingNames = currentProcessingNames || [];
        namesToNormalize = namesToNormalize || [];
        currentManufacturerNames = currentManufacturerNames || [];

        currentProcessingNames.forEach(processingName => {
            const idx = namesToNormalize.findIndex(name => processingName === name);
            if (idx >= 0) {
                namesToNormalize.splice(idx, 1)
            }
        })

        let denormalizedNamesToNormalize = {entities: {}, names: namesToNormalize};
        namesToNormalize.forEach(nameToNormalize => {
            denormalizedNamesToNormalize = {
                ...denormalizedNamesToNormalize,
                entities: {
                    ...denormalizedNamesToNormalize.entities,
                    [nameToNormalize]: {
                        submitting: false,
                        nameToNormalize: "",
                        manufacturerName: ""
                    }
                }
            }
        })

        const manufacturerOptionNames = currentManufacturerNames.map(currentManName => {
            return {value: currentManName, label: currentManName};
        })

        return {
            currentProcessingNames: currentProcessingNames,
            manufacturerNames: manufacturerOptionNames,
            namesToNormalize: denormalizedNamesToNormalize
        }
    } catch (error) {
        throw error;
    }
}

export const processNewManufacturerAlias = async (aliasName, manufacturerName) => {
    try {
        const response = await fetch(`${apiRootDomain}/manufacturers/add`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                "X-Authorization": await getUserJwt()
            },
            body: JSON.stringify({
                manufacturerName,
                aliasName
            })
        })
        const data = await response.json();
    } catch (error) {
        throw error
    }
}

export const addNewRootManufacturer = async (newManufacturerName) => {
    try {
        const res = await fetch(`${apiRootDomain}/manufacturers/root-add`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'X-Authorization': await getUserJwt(),
            },
            body: JSON.stringify({
                rootManufacturer: newManufacturerName
            })
        });
        if (res.status === 400) {
            throw new Error("nope")
        }

    } catch (error) {
        throw error;
    }
}

export const getManufacturerSample = async (manufacturerName) => {
    try {
        const res = await fetch(`${apiRootDomain}/manufacturers/sample`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'X-Authoriztaion': await getUserJwt(),
            },
            body: JSON.stringify({
                manufacturerName: manufacturerName,
            })
        });
        if (res.status === 400) {
            throw Error("failed")
        }
        return await res.json();
    } catch (error) {
        throw error;
    }
}

export const getReportingData = async () => {
    try {
        const resJSON = await fetch(`${apiRootDomain}/reporting`, {
            method: 'GET',
            headers: {
                "X-Authorization": await getUserJwt(),
            }
        });
        const res = await resJSON.json();
        const {buyNowSuppliers, datasheetManufacturers, referenceDesignManufacturers} = res;
        return {
            buyNowSuppliers: RemoveBlankAndAddAllAndSort(buyNowSuppliers),
            datasheetManufacturers: RemoveBlankAndAddAllAndSort(datasheetManufacturers),
            referenceDesignManufacturers: RemoveBlankAndAddAllAndSort(referenceDesignManufacturers),
        }
    } catch (error) {
        throw error;
    }
}

export const getUserInfo = async () => {
    try {
        const responseJSON = await fetch(`${apiRootDomain}/user`, {
            method: "GET",
            headers: {
                'X-Authorization': await getUserJwt(),
            }
        });
        return await responseJSON.json();
    } catch (error) {
        throw error;
    }
}

export const exportBuyNowCSVMetrics = async ({filename, suppliers, startDate, endDate}) => {
    try {
        const res = await fetch(`${apiRootDomain}/reporting/metrics?type=buynow`, {
            method: 'post',
            headers: {
                "X-Authorization": await getUserJwt()
            },
            body: JSON.stringify({
                suppliers,
                startDate,
                endDate,
            }),
        });

        return await res.json();
    } catch (error) {
        throw error;
    }
}

export const exportBuyNowCSV = async ({filename, suppliers, startDate, endDate}) => {
    try {
        const blob = await exportReports(JSON.stringify({
            suppliers,
            startDate,
            endDate,
        }), "buynow")

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        if (filename.trim() === "") {
            const now = new Date();
            const dateName = `${now.getDate()} ${
                monthNames[now.getMonth()]
            } ${now.getFullYear()}`
            filename = `buy-now-clickout-${dateName}.csv`;
        }

        filename = appendCsvExt(filename)
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        throw error;
    }
}

export const exportDatasheetCSVMetrics = async ({filename, manufacturers, startDate, endDate}) => {
    try {
        const res = await fetch(`${apiRootDomain}/reporting/metrics?type=datasheets`, {
            method: 'post',
            headers: {
                "X-Authorization": await getUserJwt()
            },
            body: JSON.stringify({
                manufacturers,
                startDate,
                endDate
            }),
        });
        return await res.json();
    } catch (error) {
        throw error;
    }
}

export const exportDatasheetCSV = async ({filename, manufacturers, startDate, endDate}) => {
    try {
        const blob = await exportReports(JSON.stringify({
            manufacturers,
            startDate,
            endDate
        }), "datasheets");

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        if (filename.trim() === "") {
            const now = new Date();
            const dateName = `${now.getDate()} ${
                monthNames[now.getMonth()]
            } ${now.getFullYear()}`
            filename = `datasheet-clickout-${dateName}.csv`;
        }

        filename = appendCsvExt(filename)
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.log(error);
    }
}

export const exportReferenceDesignCSV = async ({filename, companyNames, startDate, endDate}) => {
    try {
        const blob = await exportReports(JSON.stringify({
            companies: companyNames,
            startDate,
            endDate
        }), "referencedesigns");

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        if (filename.trim() === "") {
            const now = new Date();
            const dateName = `${now.getDate()} ${
                monthNames[now.getMonth()]
            } ${now.getFullYear()}`
            filename = `reference-design-clickout-${dateName}.csv`;
        }

        filename = appendCsvExt(filename)

        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.log(error);
    }
}

export const exportReferenceDesignCSVMetrics = async ({filename, companyNames, startDate, endDate}) => {
    try {
        const res = await fetch(`${apiRootDomain}/reporting/metrics?type=referencedesigns`, {
            method: 'post',
            headers: {
                "X-Authorization": await getUserJwt()
            },
            body: JSON.stringify({
                companies: companyNames,
                startDate,
                endDate
            }),
        });
        return await res.json();
    } catch (error) {
        throw error;
    }
}

const exportReports = async (body, reportType) => {
    try {
        const res = await fetch(`${apiRootDomain}/reporting/generate?type=${reportType}`, {
            method: 'post',
            headers: {
                "X-Authorization": await getUserJwt()
            },
            body,
        });
        return await res.blob();
    } catch (error) {
        throw error;
    }
}

const appendCsvExt = (filename) => {
    if (filename.substring(filename.length - 4) !== ".csv") {
        filename = filename + ".csv"
    }
    return filename;
}

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const RemoveBlankAndAddAllAndSort = (arr) => {
    arr = arr.filter(item => item.trim() !== "")
    arr = arr.sort();
    arr = ["Include All", ...arr];
    return arr;
}