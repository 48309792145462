import {useState, useEffect, useLayoutEffect, useRef} from 'react';
import {DatePicker} from "../../components/Datepicker/Datepicker";
import MultiSelect from "react-multi-select-component";
import {
    exportBuyNowCSV, exportBuyNowCSVMetrics,
    exportDatasheetCSV, exportDatasheetCSVMetrics,
    exportReferenceDesignCSV, exportReferenceDesignCSVMetrics,
    getReportingData
} from "../../api/api";

const Reporting = () => {
    const [defaultData, setDefaultData] = useState({
        buyNowSuppliers: [],
        datasheetManufacturers: [],
        referenceDesignManufacturers: []
    })

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await getReportingData();
                const {
                    buyNowSuppliers,
                    datasheetManufacturers,
                    referenceDesignManufacturers
                } = response;

                const buyNowSuppliersNorm = buyNowSuppliers.map(supplier => ({label: supplier, value: supplier}));
                const datasheetManufacturersNorm = datasheetManufacturers.map(man => ({label: man, value: man}));
                const referenceDesignManufacturerNorm = referenceDesignManufacturers.map(ref => ({
                    label: ref,
                    value: ref
                }));

                setDefaultData({
                    ...defaultData,
                    buyNowSuppliers: buyNowSuppliersNorm,
                    datasheetManufacturers: datasheetManufacturersNorm,
                    referenceDesignManufacturers: referenceDesignManufacturerNorm,
                })
            } catch (error) {
                console.log(error);
            }
        }
        getData()
    }, [])

    return <div>
        <BuyNow
            suppliers={defaultData.buyNowSuppliers}
        />

        <Datasheet
            manufacturers={defaultData.datasheetManufacturers}
        />

        <ReferenceDesigns
            manufacturers={defaultData.referenceDesignManufacturers}
        />
    </div>
};

export default Reporting;

const ReferenceDesigns = ({manufacturers}) => {
    const [selectedOptions, setSelectedOptions] = useState({
        companyNames: [],
        filename: '',
        startDate: new Date(),
        endDate: new Date(),
        totalRecords: 0,
    })

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        const getData = async () => {
            try {
                const refDesignReportMetrics = await exportReferenceDesignCSVMetrics({
                    filename: selectedOptions.filename,
                    companyNames: selectedOptions.companyNames.map(c => c.value),
                    startDate: selectedOptions.startDate,
                    endDate: selectedOptions.endDate
                })

                setSelectedOptions({
                    ...selectedOptions,
                    totalRecords: refDesignReportMetrics.totalRecords
                });
            } catch (error) {
                console.log(error);
            }
        }
        getData();
    }, [selectedOptions.companyNames, selectedOptions.startDate, selectedOptions.endDate])


    // TODO: Create name attribute and update attributes that way
    const selectCompanyName = (data) => {
        setSelectedOptions({
            ...selectedOptions,
            companyNames: data
        })
    }

    const selectStartDate = (date) => {
        setSelectedOptions({
            ...selectedOptions,
            startDate: date
        })
    }

    const selectEndDate = (date) => {
        setSelectedOptions({
            ...selectedOptions,
            endDate: date
        })
    }

    const selectFilename = (event) => {
        event.preventDefault();
        setSelectedOptions({
            ...selectedOptions,
            filename: event.target.value
        })
    }

    const createReferenceDesignsCSV = async () => {
        try {
            await exportReferenceDesignCSV({
                filename: selectedOptions.filename,
                companyNames: selectedOptions.companyNames.companyNames.map(c => c.value),
                startDate: selectedOptions.startDate,
                endDate: selectedOptions.endDate
            })
        } catch (error) {
            console.log(error)
        }
    }

    const resetData = (event) => {
        event.preventDefault();
        setSelectedOptions({
            ...selectedOptions,
            companyNames: [],
            filename: '',
            startDate: new Date(),
            endDate: new Date(),
            totalCount: 0
        })
    }

    return (
        <div className="bg-white shadow ml-4 mt-4 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Reference Design Click-throughs</h3>
                    <p className="mt-1 text-sm text-gray-500">
                        Toggle the options to export a CSV file of reference design click-throughs
                    </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                    <form>
                        <div className="grid grid-cols-6 gap-6">
                            {/* Manufacturers Dropdown */}
                            <ReportingMultiSelect
                                selectItem={selectCompanyName}
                                list={manufacturers}
                                listName={"Company/Companies"}
                                value={selectedOptions.companyNames}
                            />

                            <div className="col-span-3 lg:col-span-3 sm:col-span-6"/>

                            <div className="col-span-3 lg:col-span-3 sm:col-span-6">
                                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                                    Start Date
                                </label>
                                <DatePicker
                                    date={selectedOptions.startDate}
                                    onChange={selectStartDate}
                                />
                            </div>

                            <div className="col-span-3 lg:col-span-3 sm:col-span-6">
                                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                                    End Date
                                </label>
                                <DatePicker
                                    date={selectedOptions.endDate}
                                    onChange={selectEndDate}
                                />
                            </div>

                            <div className="col-span-3 lg:col-span-3 sm:col-span-6">
                                <label htmlFor="postal_code" className="block text-sm font-medium text-gray-700">
                                    Exported CSV Filename
                                </label>
                                <input
                                    onChange={selectFilename}
                                    value={selectedOptions.filename}
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    autoComplete="postal-code"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>
                            <div className="col-span-3 lg:col-span-3 sm:col-span-6"/>
                        </div>
                    </form>
                </div>
            </div>
            <div className="flex justify-end py-5 mr-5">
                <b>Total Records: </b> {selectedOptions.totalRecords}
            </div>
            <div className="flex justify-end">
                <button
                    onClick={resetData}
                    type="button"
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Reset
                </button>
                <button
                    onClick={createReferenceDesignsCSV}
                    type="button"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Export
                </button>
            </div>
        </div>
    );
}

const Datasheet = ({manufacturers}) => {
    const [selectedOptions, setSelectedOptions] = useState({
        manufacturerNames: [],
        filename: '',
        startDate: new Date(),
        endDate: new Date(),
        totalRecords: 0,
    })

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        const getData = async () => {
            try {
                const datasheetMetrics = await exportDatasheetCSVMetrics({
                    filename: selectedOptions.filename,
                    manufacturers: selectedOptions.manufacturerNames.map(m => m.value),
                    startDate: selectedOptions.startDate,
                    endDate: selectedOptions.endDate
                })

                setSelectedOptions({
                    ...selectedOptions,
                    totalRecords: datasheetMetrics.totalRecords
                });
            } catch (error) {
                console.log(error);
            }
        }
        getData();

    }, [selectedOptions.manufacturerNames, selectedOptions.startDate, selectedOptions.endDate])

    // TODO: Consolidate functions and use a name to assign to individual attributes
    const selectManufacturerName = (data) => {
        setSelectedOptions({
            ...selectedOptions,
            manufacturerNames: data
        })
    }

    const selectStartDate = (date) => {
        setSelectedOptions({
            ...selectedOptions,
            startDate: date
        })
    }

    const selectEndDate = (date) => {
        setSelectedOptions({
            ...selectedOptions,
            endDate: date
        })
    }

    const selectFilename = (event) => {
        event.preventDefault();
        setSelectedOptions({
            ...selectedOptions,
            filename: event.target.value,
        });
    }

    const createDatasheetCSV = async (event) => {
        event.preventDefault();
        try {
            await exportDatasheetCSV({
                filename: selectedOptions.filename,
                manufacturers: selectedOptions.manufacturerNames.map(m => m.value),
                startDate: selectedOptions.startDate,
                endDate: selectedOptions.endDate,
            })
        } catch (error) {
            console.log(error)
        }
    }

    const resetData = (event) => {
        event.preventDefault();
        setSelectedOptions({
            ...selectedOptions,
            manufacturerNames: [],
            filename: '',
            startDate: new Date(),
            endDate: new Date(),
            totalRecords: 0,
        })
    }

    return (
        <div className="bg-white shadow ml-4 mt-4 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Datasheet Click-throughs</h3>
                    <p className="mt-1 text-sm text-gray-500">
                        Toggle the options to export a CSV file of datasheet click-throughs
                    </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                    <form>
                        <div className="grid grid-cols-6 gap-6">
                            <ReportingMultiSelect
                                selectItem={selectManufacturerName}
                                list={manufacturers}
                                listName={"Manufacturer(s)"}
                                value={selectedOptions.manufacturerNames}
                            />

                            <div className="col-span-3 lg:col-span-3 sm:col-span-6"/>

                            <div className="col-span-3 lg:col-span-3 sm:col-span-6">
                                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                                    Start Date
                                </label>
                                <DatePicker
                                    date={selectedOptions.startDate}
                                    onChange={selectStartDate}
                                />
                            </div>

                            <div className="col-span-3 lg:col-span-3 sm:col-span-6">
                                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                                    End Date
                                </label>
                                <DatePicker
                                    date={selectedOptions.endDate}
                                    onChange={selectEndDate}
                                />
                            </div>

                            <div className="col-span-3 lg:col-span-3 sm:col-span-6">
                                <label htmlFor="postal_code" className="block text-sm font-medium text-gray-700">
                                    Exported CSV Filename
                                </label>
                                <input
                                    onChange={selectFilename}
                                    value={selectedOptions.filename}
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    autoComplete="postal-code"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>
                            <div className="col-span-3 lg:col-span-3 sm:col-span-6"/>
                        </div>
                    </form>
                </div>
            </div>
            <div className="flex justify-end py-5 mr-5">
                <b>Total Records: </b> {selectedOptions.totalRecords}
            </div>
            <div className="flex justify-end">
                <button
                    onClick={resetData}
                    type="button"
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Reset
                </button>
                <button
                    onClick={createDatasheetCSV}
                    type="button"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Export
                </button>
            </div>
        </div>
    )
}

const BuyNow = ({suppliers}) => {
    const [selectedOptions, setSelectedOptions] = useState({
        suppliers: [],
        filename: '',
        startDate: new Date(),
        endDate: new Date(),
        totalRecords: 0,
    })

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        const getData = async () => {
            try {
                const buyNowMetrics = await exportBuyNowCSVMetrics({
                    suppliers: selectedOptions.suppliers.map(s => s.value),
                    filename: selectedOptions.filename,
                    startDate: selectedOptions.startDate,
                    endDate: selectedOptions.endDate
                })

                setSelectedOptions({
                    ...selectedOptions,
                    totalRecords: buyNowMetrics.totalRecords
                });
            } catch (error) {
                console.log(error);
            }
        }
        getData();

    }, [selectedOptions.suppliers, selectedOptions.startDate, selectedOptions.endDate])

    // TODO: Migrate to single function and use name to figure out which attribute to update
    const selectSupplierName = (data) => {
        setSelectedOptions({
            ...selectedOptions,
            suppliers: data
        })
    };

    const selectStartDate = (date) => {
        setSelectedOptions({
            ...selectedOptions,
            startDate: date
        })
    }

    const selectFilename = event => {
        event.preventDefault();
        setSelectedOptions({
            ...selectedOptions,
            filename: event.target.value
        })
    }

    const selectEndDate = (date) => {
        setSelectedOptions({
            ...selectedOptions,
            endDate: date
        })
    }

    const createBuyNowCSV = async (event) => {
        event.preventDefault();
        try {
            await exportBuyNowCSV({
                filename: selectedOptions.filename,
                suppliers: selectedOptions.suppliers.map(s => s.value),
                startDate: selectedOptions.startDate,
                endDate: selectedOptions.endDate
            })
        } catch (error) {
            console.log(error);
        }
    }

    const resetData = (event) => {
        event.preventDefault();
        setSelectedOptions({
            suppliers: [],
            filename: '',
            startDate: new Date(),
            endDate: new Date(),
            totalRecords: 0,
        });
    }

    return (
        <div className="bg-white shadow ml-4 mt-4 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Buy Now Clicks</h3>
                    <p className="mt-1 text-sm text-gray-500">
                        Export a buy now click through report for an individual company or multiple companies listed on
                        Design Fast
                    </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                    <form>
                        <div className="grid grid-cols-6 gap-6">
                            <ReportingMultiSelect
                                selectItem={selectSupplierName}
                                list={suppliers}
                                listName={"Company/Companies"}
                                value={selectedOptions.suppliers}
                            />

                            <div class="col-span-3 lg:col-span-3"/>

                            <div className="col-span-3 lg:col-span-3 sm:col-span-6">
                                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                                    Start Date
                                </label>
                                <DatePicker
                                    date={selectedOptions.startDate}
                                    onChange={selectStartDate}
                                />
                            </div>

                            <div className="col-span-3 lg:col-span-3 sm:col-span-6">
                                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                                    End Date
                                </label>
                                <DatePicker
                                    date={selectedOptions.endDate}
                                    onChange={selectEndDate}
                                />
                            </div>

                            <div className="col-span-3 lg:col-span-3 sm:col-span-6">
                                <label htmlFor="postal_code" className="block text-sm font-medium text-gray-700">
                                    Exported CSV Filename
                                </label>
                                <input
                                    onChange={selectFilename}
                                    value={selectedOptions.filename}
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    autoComplete="postal-code"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>
                            <div class="col-span-3 lg:col-span-3"/>
                        </div>
                    </form>
                </div>
            </div>
            <div className="flex justify-end py-5 mr-5">
                <b>Total Records: </b> {selectedOptions.totalRecords}
            </div>
            <div className="flex justify-end">
                <button
                    onClick={resetData}
                    type="button"
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Reset
                </button>
                <button
                    onClick={createBuyNowCSV}
                    type="button"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Export
                </button>
            </div>
        </div>
    )
}

const ReportingMultiSelect = ({list, listName, value, selectItem}) => (
    <div className="col-span-3 lg:col-span-3 sm:col-span-6">
        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
            {listName}
        </label>
        <MultiSelect
            hasSelectAll={false}
            options={list}
            value={value}
            labelledBy={listName}
            onChange={selectItem}
        />
    </div>
)
