import {Transition} from "@headlessui/react";
import {NavLink} from "react-router-dom";
import {useState} from 'react';
import DesignFastLogo from "../imgs/df-logo.png";
import {Auth} from 'aws-amplify';
import {useHistory} from 'react-router-dom';

const Navigation = ({children, routes, firstName, lastName, email, imageUrl}) => {
    const [isOpen, setIsOpen] = useState(true);

    const history = useHistory();

    const signOut = async () => {
        try {
            await Auth.signOut();
            history.push("/");
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="h-screen flex overflow-hidden bg-gray-100">
            {/* Off-canvas menu for mobile*/}
            <div className="md:hidden" style={isOpen ? {} : {display: 'None'}}>
                <div className="fixed inset-0 flex z-40">
                    <Transition
                        show={isOpen}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        {
                            (ref) => (
                                <div ref={ref} className="fixed inset-0">
                                    <div className="absolute inset-0 bg-gray-600 opacity-75"/>
                                </div>
                            )
                        }
                    </Transition>

                    <Transition
                        show={isOpen}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        {
                            (ref) => (
                                <div
                                    ref={ref}
                                    className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            onClick={() => setIsOpen(!isOpen)}
                                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                            <span className="sr-only">Close sidebar</span>
                                            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg"
                                                 fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                                 aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M6 18L18 6M6 6l12 12"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                        <div className="flex-shrink-0 flex items-center px-4">
                                            <img className="h-8 w-auto"
                                                 src={DesignFastLogo}
                                                 alt="Design Fast Logo"
                                            />
                                        </div>
                                        <nav className="mt-5 px-2 space-y-1">
                                            {
                                                routes.map(route => (
                                                    <NavLink
                                                        exact={true}
                                                        key={route.path}
                                                        to={route.path}
                                                        className={"text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"}
                                                        activeClassName={"bg-gray-900"}
                                                    >
                                                        {route.icon}
                                                        {route.name}
                                                    </NavLink>
                                                ))
                                            }
                                        </nav>
                                    </div>
                                    <div className="flex-shrink-0 flex bg-gray-700 p-4">
                                        <a href="#" className="flex-shrink-0 group block">
                                            <div className="flex items-center">
                                                <div>
                                                    <img
                                                        className="inline-block h-10 w-10 rounded-full"
                                                        src={imageUrl}
                                                        alt={`Photo of User ${firstName} ${lastName}`}
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <p className="text-base font-medium text-white">
                                                        {firstName} {lastName}
                                                    </p>
                                                    <p
                                                        onClick={signOut}
                                                        className="text-sm font-medium text-gray-400 group-hover:text-gray-300">
                                                        Sign Out
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            )
                        }
                    </Transition>
                    <div className="flex-shrink-0 w-14"/>
                </div>
            </div>

            <div className="hidden md:flex md:flex-shrink-0">
                <div className="flex flex-col w-64">
                    <div className="flex flex-col h-0 flex-1 bg-gray-800">
                        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                            <div className="flex items-center flex-shrink-0 px-4">
                                <img className="h-8 w-auto"
                                     src={DesignFastLogo}
                                     alt="Design Fast Logo"/>
                            </div>
                            <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
                                {
                                    routes.map(route => (
                                        <NavLink
                                            exact={true}
                                            key={route.path}
                                            to={route.path}
                                            className={"text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"}
                                            activeClassName={"bg-gray-900"}
                                        >
                                            {route.icon}
                                            {route.name}
                                        </NavLink>
                                    ))
                                }
                            </nav>
                        </div>
                        <div className="flex-shrink-0 flex bg-gray-700 p-4">
                            <a href="#" className="flex-shrink-0 w-full group block">
                                <div className="flex items-center">
                                    <div>
                                        <img className="inline-block h-9 w-9 rounded-full"
                                             src={imageUrl}
                                             alt={`Photo of User ${firstName} ${lastName}`}/>
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-white">
                                            {firstName} {lastName}
                                        </p>
                                        <p
                                            onClick={signOut}
                                            className="text-xs font-medium text-gray-300 group-hover:text-gray-200"
                                        >
                                            Sign Out
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open sidebar</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M4 6h16M4 12h16M4 18h16"/>
                        </svg>
                    </button>
                </div>
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
                    {children}
                </main>
            </div>
        </div>
    );
}

export default Navigation;