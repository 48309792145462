import logo from '../../imgs/df-logo.png';
import {Auth} from 'aws-amplify'
import {useState} from 'react';
import {useHistory} from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isAuthenticating, toggleAuthenticating] = useState(false);
    const history = useHistory();

    const login = async (e) => {
        e.preventDefault();
        toggleAuthenticating(true);
        try {
            await Auth.signIn(username, password);
            toggleAuthenticating(false);
            history.push("/dashboard")
        } catch (error) {
            console.log('error signing in: ', error);
            toggleAuthenticating(false);
        }
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <img className="mx-auto h-12 w-auto"
                         src={logo} alt="Design Fast Logo"/>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
                        Sign Into Your Account
                    </h2>
                </div>
                <form className="mt-8 space-y-6" action="#" method="POST">
                    <input type="hidden" name="remember" value="true"/>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">Email address</label>
                            <input
                                onChange={(e) => {
                                    const {value} = e.target;
                                    setUsername(value);
                                }}
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Email address"
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">Password</label>
                            <input
                                onChange={(e) => {
                                    const {value} = e.target;
                                    setPassword(value);
                                }}
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Password"
                            />
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <input id="remember_me" name="remember_me" type="checkbox"
                                   className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"/>
                            <label htmlFor="remember_me" className="ml-2 block text-sm text-white">
                                Remember me
                            </label>
                        </div>

                        <div className="text-sm">
                            <a href=""
                               className="font-medium text-indigo-600 hover:text-indigo-500 df-text-color">
                                Forgot your password?
                            </a>
                        </div>
                    </div>

                    <div>
                        {
                            isAuthenticating
                                ?
                                <span className="inline-flex rounded-md shadow-sm">
                                  <button
                                      style={{
                                          pointerEvents: "none"
                                      }}
                                      type="button"
                                      className="df-button group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600"
                                      disabled>
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                         xmlns="http://www.w3.org/2000/svg" fill="none"
                                         viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                              strokeWidth="4"/>
                                      <path className="opacity-75" fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                                    </svg>
                                    Processing
                                  </button>
                                </span>
                                :
                                <button
                                    onClick={login}
                                    type="submit"
                                    className="df-button group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                  <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                                       xmlns="http://www.w3.org/2000/svg"
                                       viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path fillRule="evenodd"
                                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                        clipRule="evenodd"/>
                                </svg>
                              </span>
                                    Sign in
                                </button>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;