import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Index from "./pages/Index";
import Manufacturers from "./pages/Manufacturers";
import Navigation from "./components/Navigation";
import Login from "./pages/Login";
import Reporting from "./pages/Reporting";
import ProtectedRoute from "./components/ProtectedRoute";
import Amplify, {} from 'aws-amplify';
import {useEffect, useState} from "react";
import {getUserInfo, getUserJwt} from "./api/api";

Amplify.configure({
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    },
    language: "us"
})

const routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        accessLevel: 'Reporter',
        icon: (
            <svg
                className="text-gray-300 mr-4 h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0
                        001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
            </svg>),
    },
    {
        path: '/manufacturers',
        name: 'Manufacturers',
        accessLevel: 'Administrator',
        icon: (
            <svg
                className="text-gray-300 mr-4 h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
                />
            </svg>),
    },
    {
        path: '/reporting',
        name: 'Reporting',
        accessLevel: 'Reporter',
        icon: (
            <svg
                className="text-gray-300 mr-4 h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
            </svg>
        )
    }
];

function App() {
    const [isAuthenticated, toggleAuthentication] = useState(false);
    const [isLoaded, toggleLoaded] = useState(true);
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        imageUrl: "",
        accessLevel: ""
    });

    useEffect(() => {
        console.log("Fired again...")
        // toggleLoaded(false)
        // const data = async () => {
        //     try {
        //         // Fetch JWT -- if JWT doesnt exist, we die and know user is not authenticated, no cookie set
        //         await getUserJwt();
        //         // User is authenticated, get userinfo from db
        //         const {accessLevel, firstName, lastName, email, imageUrl} = await getUserInfo();
        //
        //         setUser({
        //             ...user,
        //             accessLevel,
        //             firstName,
        //             lastName,
        //             email,
        //             imageUrl
        //         });
        //
        //         toggleAuthentication(true);
        //     } catch (error) {
        //         console.log(error)
        //     } finally {
        //         toggleLoaded(true);
        //     }
        // }
        // data();
    }, [])

    if (!isLoaded) return null;

    return (
        <Router>
            <Switch>
                {/* Unprotected Administrative Login Route */}
                <Route
                    exact={true}
                    path={"/"}
                >
                    <Login/>
                </Route>

                {/* Every Route Is Protected Except The First */}
                <Navigation
                    routes={routes}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    email={user.email}
                    imageUrl={user.imageUrl}
                >
                    <Route
                        accessLevel="Reporter"
                        path="/dashboard"
                    >
                        <Index/>
                    </Route>

                    <Route
                        accessLevel="Administrator"
                        path="/manufacturers"
                    >
                        <Manufacturers/>
                    </Route>

                    <Route
                        accessLevel="Reporter"
                        path="/reporting"
                    >
                        <Reporting/>
                    </Route>
                </Navigation>
            </Switch>
        </Router>
    )
}

export default App;
