import {useEffect, useState} from "react";
import {getMetrics} from "../../api/api";
import Loader from "../../components/Loader";

const Index = () => {
    const [appMetrics, setAppMetrics] = useState({
        totalBuyNows: 0,
        totalBuyNows30DaysAgo: 0,
        totalDatasheets: 0,
        totalDatasheets30DaysAgo: 0,
        totalComponents: 0,
        topQueries30: [],
        topQueriesAllTime: [],
        supplierNames: [],
        totalSuppliers: 0,
        supplierBuyNowCount: [],
        manufacturerDatasheetCount: []
    })

    const [isFetching, setFetching] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                const metrics = await getMetrics();
                console.log(metrics);
                setAppMetrics({
                    ...appMetrics,
                    ...metrics
                })
            } catch (error) {
                console.log(error)
            } finally {
                setFetching(false);
            }
        }
        setFetching(true);
        getData();
    }, [])

    if (isFetching) {
        return <Loader/>
    }

    return (
        <div className="flex-1 relative z-0 overflow-y-auto focus:outline-none gap-2 pl-2 pr-2">
            <Header/>
            <BulkThreeColumnDisplay
                metricTitle={"Buy Now Clicks"}
                metricOneTitle={"Last 30 Days"}
                metricOne={appMetrics.totalBuyNows30DaysAgo}
                metricTwoTitle={"Last 60 Days"}
                metricTwo={appMetrics.totalBuyNows60DaysAgo}
                metricThreeTitle={"Total"}
                metricThree={appMetrics.totalBuyNows}
            />

            <BulkThreeColumnDisplay
                metricTitle={"Datasheet Clicks"}
                metricOneTitle={"Last 30 Days"}
                metricOne={appMetrics.totalDatasheets30DaysAgo}
                metricTwoTitle={"Last 60 Days"}
                metricTwo={appMetrics.totalDatasheets30DaysAgo}
                metricThreeTitle={"Total"}
                metricThree={appMetrics.totalDatasheets}
            />

            <BulkThreeColumnDisplay
                metricTitle={"Information"}
                metricOneTitle={"Total Suppliers"}
                metricOne={appMetrics.supplierCount}
                metricTwoTitle={"Total Manufacturers"}
                metricTwo={appMetrics.manufacturerCount}
                metricThreeTitle={"Total Components"}
                metricThree={appMetrics.totalComponents}
            />

            <SuppliersAndManufacturersMetrics
                supplierBuyNowMetrics={appMetrics.supplierBuyNowCount}
                manufacturerDatasheetCount={appMetrics.manufacturerDatasheetCount}
            />

            <TwoTableDisplay
                topQueries30Days={appMetrics.topQueries30Days}
                topQueriesAllTime={appMetrics.topQueriesAllTime}
            />
        </div>
    )
};

const TwoTableDisplay = ({topQueries30Days, topQueriesAllTime}) => (
    <div className="grid grid-cols-2 gap-2 mt-5">

        <div className="flex flex-col">
            <h2 className="text-lg leading-6 font-medium text-gray-900">
                Top Queries In Last 30 Days
            </h2>
            <div className="-my-2 overflow-x-auto overflow-y-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div
                        className="shadow overflow-y-scroll overflow-hidden border-b border-gray-200 sm:rounded-lg"
                        style={{
                            maxHeight: "200px"
                        }}
                    >
                        <table className="overflow-x-auto min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white"
                                >
                                    Query
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white"
                                >
                                    Query Count
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                topQueries30Days && topQueries30Days.map((metric, idx) => {
                                    if (idx % 2)
                                        return (
                                            <tr className="bg-white">
                                                <td
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                                >
                                                    {metric.query}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {metric.count}
                                                </td>
                                            </tr>
                                        )
                                    return (
                                        <tr className="bg-gray-50">
                                            <td
                                                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                            >
                                                {metric.query}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {metric.count}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex flex-col">
            <h2 className="text-lg leading-6 font-medium text-gray-900">
                Top Queries Of All Time
            </h2>
            <div className="-my-2 overflow-x-auto overflow-y-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div
                        className="shadow overflow-y-scroll overflow-hidden border-b border-gray-200 sm:rounded-lg"
                        style={{
                            maxHeight: "200px"
                        }}
                    >
                        <table className="overflow-x-auto min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white"
                                >
                                    Query
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white"
                                >
                                    Query Count
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                topQueriesAllTime && topQueriesAllTime.map((metric, idx) => {
                                    if (idx % 2)
                                        return (
                                            <tr className="bg-white">
                                                <td
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                                >
                                                    {metric.query}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {metric.count}
                                                </td>
                                            </tr>
                                        )
                                    return (
                                        <tr className="bg-gray-50">
                                            <td
                                                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                            >
                                                {metric.query}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {metric.count}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

const SuppliersAndManufacturersMetrics = ({supplierBuyNowMetrics, manufacturerDatasheetCount}) => {
    return (
        <div className="grid grid-cols-2 gap-2 mt-5">
            {/* Supplier Clicks */}
            <div className="flex flex-col">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                    Buy Now Per Company
                </h2>
                <div className="-my-2 overflow-x-auto overflow-y-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div
                            className="shadow overflow-y-scroll overflow-hidden border-b border-gray-200 sm:rounded-lg"
                            style={{
                                maxHeight: "200px"
                            }}
                        >
                            <table className="overflow-x-auto min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white"
                                    >
                                        Company
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white"
                                    >
                                        Part Count
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white"
                                    >
                                        Click Count
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    supplierBuyNowMetrics.map((metric, idx) => {
                                        if (idx % 2)
                                            return (
                                                <tr className="bg-white">
                                                    <td
                                                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                                    >
                                                        {metric.name}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        COMING SOON
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {metric.count}
                                                    </td>
                                                </tr>
                                            )
                                        return (
                                            <tr className="bg-gray-50">
                                                <td
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                                >
                                                    {metric.name}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    COMING SOON
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {metric.count}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Manufacturer Clicks */}
            <div className="flex flex-col">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                    Datasheet Click Per Company
                </h2>
                <div className="-my-2 overflow-x-auto overflow-y-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div
                            className="shadow overflow-y-scroll overflow-hidden border-b border-gray-200 sm:rounded-lg"
                            style={{
                                maxHeight: "200px"
                            }}
                        >
                            <table className="overflow-x-auto min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white"
                                    >
                                        Company
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white"
                                    >
                                        Part Count
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 bg-white"
                                    >
                                        Click Count
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    manufacturerDatasheetCount.map((metric, idx) => {
                                        if (idx % 2)
                                            return (
                                                <tr className="bg-white">
                                                    <td
                                                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                                    >
                                                        {metric.name}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        COMING SOON
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {metric.count}
                                                    </td>
                                                </tr>
                                            )
                                        return (
                                            <tr className="bg-gray-50">
                                                <td
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                                >
                                                    {metric.name}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    COMING SOON
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {metric.count}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Header = () => {
    return (
        <div
            className="bg-white border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
            <div className="flex-1 min-w-0">
                <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                    Design Fast Overview
                </h1>
            </div>
            <div className="mt-4 flex sm:mt-0 sm:ml-4"/>
        </div>
    )
}

const BulkThreeColumnDisplay = ({
                                    metricTitle,
                                    metricOneTitle,
                                    metricOne,
                                    metricTwoTitle,
                                    metricTwo,
                                    metricThreeTitle,
                                    metricThree
                                }) => {
    return (
        <div className="mt-8">
            <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-10">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                    {metricTitle}
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">

                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">

                                </div>
                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt className="text-sm font-medium text-gray-500 truncate">
                                            {metricOneTitle}
                                        </dt>
                                        <dd>
                                            <div className="text-lg font-medium text-gray-900">
                                                {numberWithCommas(metricOne)}
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        {/*<div className="bg-gray-50 px-5 py-3">*/}
                        {/*    <div className="text-sm">*/}
                        {/*        <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">*/}
                        {/*            View all*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">

                                </div>
                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt className="text-sm font-medium text-gray-500 truncate">
                                            {metricTwoTitle}
                                        </dt>
                                        <dd>
                                            <div className="text-lg font-medium text-gray-900">
                                                {numberWithCommas(metricTwo)}
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        {/*<div className="bg-gray-50 px-5 py-3">*/}
                        {/*    <div className="text-sm">*/}
                        {/*        <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">*/}
                        {/*            View all*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">

                                </div>
                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt className="text-sm font-medium text-gray-500 truncate">
                                            {metricThreeTitle}
                                        </dt>
                                        <dd>
                                            <div className="text-lg font-medium text-gray-900">
                                                {numberWithCommas(metricThree)}
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        {/*<div className="bg-gray-50 px-5 py-3">*/}
                        {/*    <div className="text-sm">*/}
                        {/*        <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">*/}
                        {/*            View all*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

const numberWithCommas = (x) => {
    if (!x) return x;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default Index;