import {useState, useEffect, Fragment} from 'react';
import {
    addNewRootManufacturer,
    fetchManufacturerNames,
    processNewManufacturerAlias,
    getManufacturerSample
} from "../../api/api";
import {Dialog, Transition} from '@headlessui/react'
import WindowedSelect from "react-windowed-select";
import Levenshtein from "js-levenshtein";

const Manufacturers = () => {
    const [namesToNormalize, setNamesToNormalize] = useState({entities: {}, names: []})
    const [currentProcessingNames, setNamesToProcess] = useState([])
    const [currentManufacturerNames, setManufacturerNames] = useState([])
    const [isModalOpen, toggleModal] = useState(false);
    const [newManufacturerName, changeNewManufacturerName] = useState('');
    const [isSampleModalOpen, toggleSampleModalOpen] = useState(false);
    const [sampleData, setSampleData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const {
                    currentProcessingNames,
                    manufacturerNames,
                    namesToNormalize
                } = await fetchManufacturerNames()

                const newEntities = {};
                namesToNormalize.names.forEach(n => {
                    newEntities[n] = {
                        submitting: false,
                        nameToNormalize: "",
                        manufacturerName: "",
                        suggestion: suggest(manufacturerNames, n)
                    }
                });

                const update = {entities: newEntities, names: namesToNormalize.names};

                setNamesToProcess(currentProcessingNames || []);
                setNamesToNormalize(update);
                setManufacturerNames(manufacturerNames || []);
            } catch (error) {
                console.log(error);
            }
        }

        fetchData().catch(e => console.log(e));
    }, [])

    const submitForProcessing = async (aliasName, manufacturerName) => {
        try {
            setNamesToNormalize({
                ...namesToNormalize,
                entities: {
                    ...namesToNormalize.entities,
                    [aliasName]: {
                        ...namesToNormalize.entities[aliasName],
                        submitting: true
                    }
                }
            })

            await processNewManufacturerAlias(aliasName, manufacturerName)

            setNamesToProcess([...currentProcessingNames, aliasName]);
            let newNamesToNormalize = {...namesToNormalize.entities}
            delete (newNamesToNormalize[aliasName])
            let newNames = [...namesToNormalize.names];
            const idx = newNames.findIndex(name => name === aliasName);
            newNames.splice(idx, 1)

            setNamesToNormalize({
                entities: newNamesToNormalize,
                names: newNames,
            })
        } catch (error) {
            console.log(error);
        }
    }

    const manufacturerNameSelected = (aliasName, manufacturerName) => {
        setNamesToNormalize({
            ...namesToNormalize,
            entities: {
                ...namesToNormalize.entities,
                [aliasName]: {
                    ...namesToNormalize.entities[aliasName],
                    manufacturerName: manufacturerName,
                    nameToNormalize: aliasName
                }
            }
        })
    }

    const c = (e) => {
        e.preventDefault();
        changeNewManufacturerName('');
        toggleModal(true);
    }

    const submitNewManufacturerName = async (e) => {
        e.preventDefault();
        const newManName = newManufacturerName.trim();
        if (newManName === "") {
            return;
        }

        try {
            await addNewRootManufacturer(newManufacturerName);
            const manNames = [...currentManufacturerNames, {value: newManName, label: newManName}];
            setManufacturerNames(manNames);

            const newEntities = {};
            namesToNormalize.names.forEach(n => {
                newEntities[n] = {
                    submitting: false,
                    nameToNormalize: "",
                    manufacturerName: "",
                    suggestion: suggest(manNames, n)
                }
            });

            const update = {entities: newEntities, names: namesToNormalize.names};
            setNamesToNormalize(update);
        } catch (error) {
            console.error("didnt work ", error)
        } finally {
            toggleModal(false);
        }
    }

    const suggest = (manufacturerNames, nameID) => {
        let highestScore = 100;
        let suggestion = "";

        manufacturerNames.forEach(mName => {
            const score = Levenshtein(mName.value.toLowerCase(), nameID.toLowerCase())
            if (score < highestScore) {
                highestScore = score
                suggestion = mName.value
            }
        });

        return suggestion
    }

    const openSampleWithModal = async (manufacturerName) => {
        try {
            const data = await getManufacturerSample(manufacturerName);
            setSampleData(data.body);
            toggleSampleModalOpen(true);
        } catch (error) {
            toggleSampleModalOpen(false);
            setSampleData(null);
        }
    }

    return (
        <div className="px-4">
            <div>
                <button
                    onClick={c}
                    type="button"
                    className="stickything inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Create New Manufacturer
                </button>
            </div>
            <div className="py-5">
                <div className="text-2xl py-2"> Currently Processing Manufacturers</div>
                {currentProcessingNames.map(processName => (
                    <div
                        key={processName}
                        className="flex flex-grow">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                             xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    strokeWidth="4"/>
                            <path className="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                        </svg>
                        {processName}
                    </div>
                ))}
            </div>

            <ManufacturerNameToNormalize
                namesToNormalize={namesToNormalize}
                currentManufacturerNames={currentManufacturerNames}
                manufacturerNameSelected={manufacturerNameSelected}
                submitForProcessing={submitForProcessing}
                openSampleWithModal={openSampleWithModal}
            />

            {
                isModalOpen ?
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div
                            className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition
                                show={isModalOpen}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                {(ref) => (
                                    <div
                                        ref={ref}
                                        className="fixed inset-0 transition-opacity" aria-hidden="true">
                                        <div className="absolute inset-0 bg-gray-500 opacity-75"/>
                                    </div>
                                )}
                            </Transition>

                            <Transition
                                show={isModalOpen}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {(ref) => (
                                    <div
                                        ref={ref}
                                        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
                                        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                                        <div>
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="manufacturer_name"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Manufacturer Name
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <input
                                                        type="text"
                                                        name="manufacturer_name"
                                                        id="manufacturer_name"
                                                        value={newManufacturerName}
                                                        onChange={(e) => changeNewManufacturerName(e.target.value)}
                                                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-5">
                                            <div className="flex justify-end">
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        toggleModal(false)
                                                        changeNewManufacturerName('');
                                                    }}
                                                    type="button"
                                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                    Cancel
                                                </button>
                                                <button
                                                    onClick={submitNewManufacturerName}
                                                    type="submit"
                                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Transition>
                        </div>
                    </div> : <div/>
            }

            {/*<SampleModal open={isSampleModalOpen} setOpen={toggleSampleModalOpen} data={sampleData}/>*/}

        </div>
    );
};

export default Manufacturers;

const validate = (obj, property) => {
    if (!obj) return;
    if (obj[property]) return obj[property];
    return ""
}


const SampleModal = ({open, setOpen, data}) => {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" static className="fixed z-15 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
                <div
                    className="flex items-end justify-center min-h-screen pt-8 px-10 pb-30 text-center sm:block sm:p-10">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg px-8 pt-58 pb-8 text-left overflow-hidden shadow-xl transform transition-all sm:my-10 sm:align-middle sm:w-full sm:p-8">
                            <div>
                                Manufacturer Name: {validate(data, "manufacturer_name")}
                                <br/>
                                Manufacturer Part Number: {validate(data, "manufacturer_part_number")}
                                <br/>
                                Supplier Name: {validate(data, "name")}
                                <br/>
                                Product URL: <a href={validate(data, 'product_url')}
                                                className="no-underline hover:underline text-indigo-600 text-xl"
                                                target={"_blank"}>Click Me</a>
                                <br/>
                                Datasheet URL: {validate(data, "datasheet_url")}
                                <br/>
                                Description: {validate(data, "description")}
                                <br/>
                                Image URL: {validate(data, "image_url")}
                                <br/>
                                Minimum Order Qty: {validate(data, "minimum_order_qty")}
                                <br/>
                                Stock: {validate(data, "stock")}
                                <br/>
                                Package Type: {validate(data, "package_type")}
                            </div>
                            <div className="mt-5 sm:mt-6">
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                    onClick={() => setOpen(false)}
                                >
                                    Go back to Manufacturers
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

const ManufacturerNameToNormalize = ({
                                         namesToNormalize,
                                         manufacturerNameSelected,
                                         submitForProcessing,
                                         currentManufacturerNames,
                                         openSampleWithModal
                                     }) => {
    return (<div>
        <div className={"text-2xl"}> Manufacturer Names To Process</div>
        {namesToNormalize.names.slice(0, namesToNormalize.names.length / 20).map(nameID => {
            const nameToNormalize = namesToNormalize.entities[nameID];
            if (!nameToNormalize) return;
            if (nameToNormalize.submitting) {
                return (
                    <div key={nameID} id="name-to-normalize flex flex-grow">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                             xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    strokeWidth="4"/>
                            <path className="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                        </svg>
                        {nameID}
                    </div>
                )
            }

            return (
                <div className="py-2">
                    <div htmlFor="add_team_members" className="">
                        Select A Manufacturer For <b>{nameID}</b>
                    </div>
                    <div style={{
                        padding: '20px'
                    }}>
                        Suggestion <b>{nameToNormalize.suggestion}</b> <span className="ml-3">
                                  <button
                                      onClick={(e) => {
                                          e.preventDefault();
                                          manufacturerNameSelected(nameID, nameToNormalize.suggestion)
                                          submitForProcessing(nameID, nameToNormalize.suggestion)
                                      }}
                                      type="button"
                                      className="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500">
                                      <svg className="-ml-2 mr-1 h-5 w-5 text-gray-400"
                                           xmlns="http://www.w3.org/2000/svg"
                                           viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                      <path fillRule="evenodd"
                                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                            clipRule="evenodd"/>
                                    </svg>
                                    <span>Pick Suggestion & Process </span>
                                  </button>
                                </span>
                    </div>

                    <div>
                        <button
                            className="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
                            onClick={e => {
                                e.preventDefault();
                                openSampleWithModal(nameID);
                            }}>
                            Open Sample
                        </button>
                    </div>

                    <div className="flex">
                        <div className="flex-grow">
                            <WindowedSelect
                                options={currentManufacturerNames}
                                onChange={(opt) => {
                                    manufacturerNameSelected(nameID, opt.value)
                                }}
                            />
                        </div>
                        <span className="ml-3">
                                  <button
                                      onClick={(e) => {
                                          e.preventDefault();
                                          submitForProcessing(nameToNormalize.nameToNormalize, nameToNormalize.manufacturerName)
                                      }}
                                      type="button"
                                      className="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500">
                                      <svg className="-ml-2 mr-1 h-5 w-5 text-gray-400"
                                           xmlns="http://www.w3.org/2000/svg"
                                           viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                      <path fillRule="evenodd"
                                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                            clipRule="evenodd"/>
                                    </svg>
                                    <span>Submit</span>
                                  </button>
                                </span>
                    </div>
                </div>
            )
        })}
    </div>)
}